export enum ApiUniqueNames {
  CREATE_SESSION = "createSession",
  NOTIFY_EVENT = "notifyEvent",
  CREATE_TAB = "createTab",
  GET_TABS = "getTabs",
  SET_PRESENCE = "setPresence",
  GET_PRESENCE = "getPresence",
  GET_ENVIRONMENT = "getEnvironment",
  SET_MODE = "setMode",
  GET_MODE = "getMode",
  SET_WIDTH = "setWidth",
  GET_WIDTH = "getWidth",
}
